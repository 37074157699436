@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion) {
  html,
  body {
    scroll-behavior: auto;
  }
}

@layer utilities {
  @counter-style checkmark {
    system: cyclic;
    symbols: "✓";
    suffix: " ";
  }
}
